@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'YuGothic';
  src: url('./fonts/yu-gothic.woff2') format('woff2'),
       url('./fonts/yu-gothic.woff') format('woff'),
       url('./fonts/yu-gothic.ttf') format('truetype');
  /* Add additional font formats if necessary */
  /* Specify the correct path to the font files */
}

@font-face {
  font-family: 'YuGothicMd';
  src: url('./fonts/yu-gothic-medium.ttf') format('truetype');
  /* Add additional font formats if necessary */
  /* Specify the correct path to the font files */
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'YuGothic', 'YuGothicMd',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
